@import "variables";

/*==========================================================================*/
/* Global Featured view - Start  ===========================================*/
/*==========================================================================*/

.tpl-architect-global-featured {
    position: relative;
    color: $color-dark;
    background-color: $color-white;

    .tpl-centered {
        max-width: 930px;
        position: relative;
    }

    .tpl-content {
        //min-height: 550px;
        height: auto;
        text-align: center;
        padding: 65px 20px 55px;

        .tpl-logo-wrapper {
            max-width: 379px;
            margin: 0 auto;
        }

        .tpl-title {
            color: inherit;
            word-wrap: break-word;
            text-transform: uppercase;
            font-size: 65px;
            font-weight: 700;
            margin: 15px 0;
        }

        .tpl-summary {
            font-size: 25px;
            line-height: 1.3;
            font-weight: 300;
            margin: 15px 0;
        }

        .tpl-button-holder {
            margin: 35px 0 20px;
        }

        .tpl-more-info {
            margin: 10px 0;
        }

        .tpl-play-button-holder {
            margin: 20px 0;
        }
    }

    /* Custom Class - 1/2 Col */
    &.tpl-1-2-col {
        float: left;
        width: 50%;
        padding: 0 15px;

        .tpl-content {
            //min-height: 0;
            padding: 65px 20px;
        }
    }

    /* Custom Class - White Text */
    &.white-text {
        color: $color-white;
    }

    /* Custom Class - Text Shadow - Light */
    &.text-shadow-light .tpl-content {
        .tpl-title,
        .tpl-summary,
        .tpl-more-info {
            text-shadow: 1px 1px 1px rgba(238, 238, 238, 0.7);
        }
    }

    /* Custom Class - Text Shadow - Dark */
    &.text-shadow-dark .tpl-content {
        .tpl-title,
        .tpl-summary,
        .tpl-more-info {
            text-shadow: 1px 1px 1px #666;
        }
    }


    /* Custom Class - Height Auto */
    &.tpl-height-auto .tpl-content {
        //min-height: 0;
    }

    /* Custom Class - Button - Green */
    &.tpl-child-button-green .tpl-button {
        @include tpl-button($color-green, true);
    }
    /* Custom Class - Button - White */
    &.tpl-child-button-white .tpl-button {
        @include tpl-button($color-white);
    }


    /* Custom Classes - Padding */
    &.bottom-padding-20 {
        padding-bottom: 20px;
    }
    &.bottom-padding-50 {
        padding-bottom: 50px;
    }
    &.bottom-padding-100 {
        padding-bottom: 100px;
    }
    &.bottom-padding-200 {
        padding-bottom: 200px;
    }

    &.top-padding-20 {
        padding-top: 20px;
    }
    &.top-padding-50 {
        padding-top: 50px;
    }
    &.top-padding-100 {
        padding-top: 100px;
    }
    &.top-padding-200 {
        padding-top: 200px;
    }

    &.left-padding-20 {
        padding-left: 20px;
    }
    &.left-padding-50 {
        padding-left: 50px;
    }
    &.left-padding-100 {
        padding-left: 100px;
    }
    &.left-padding-200 {
        padding-left: 200px;
    }

    &.right-padding-20 {
        padding-right: 20px;
    }
    &.right-padding-50 {
        padding-right: 50px;
    }
    &.right-padding-100 {
        padding-right: 100px;
    }
    &.right-padding-200 {
        padding-right: 200px;
    }



    /* Responsive */
    @media (max-width: 1250px) {
        .tpl-content .tpl-title {
            font-size: 46px;
        }
    }


    @media (max-width: 960px) {
        .tpl-content {
            padding-left: 15%;
            padding-right: 15%;

            .tpl-title {
                font-size: 34px;
                line-height: 1;
            }
            .tpl-summary {
                font-size: 16px;
            }
            .tpl-button {
                @include tpl-button-base-small;
            }
        }

        &.left-padding-20,
        &.left-padding-50,
        &.left-padding-100,
        &.left-padding-200 {
            padding-left: 0 !important;
        }
        &.right-padding-20,
        &.right-padding-50,
        &.right-padding-100,
        &.right-padding-200 {
            padding-right: 0 !important;
        }
        &.medium-top-padding-20 {
            padding-top: 20px !important;
        }
        &.medium-top-padding-50 {
            padding-top: 50px !important;
        }
        &.medium-top-padding-100 {
            padding-top: 100px !important;
        }
        &.medium-top-padding-200 {
            padding-top: 200px !important;
        }
        &.medium-bottom-padding-20 {
            padding-bottom: 20px !important;
        }
        &.medium-bottom-padding-50 {
            padding-bottom: 50px !important;
        }
        &.medium-bottom-padding-100 {
            padding-bottom: 100px !important;
        }
        &.medium-bottom-padding-200 {
            padding-bottom: 200px !important;
        }
    }


    @media (max-width: 700px) {
        &.tpl-1-2-col {
            float: none;
            width: 100%;
            padding: 50px 15px;
        }
    }


    @media (max-width: 650px) {
        .tpl-content {
            //min-height: 0;
            padding: 50px 20px 40px;
        }
        &.small-top-padding-20 {
            padding-top: 20px !important;
        }
        &.small-top-padding-50 {
            padding-top: 50px !important;
        }
        &.small-top-padding-100 {
            padding-top: 100px !important;
        }
        &.small-top-padding-200 {
            padding-top: 200px !important;
        }
        &.small-bottom-padding-20 {
            padding-bottom: 20px !important;
        }
        &.small-bottom-padding-50 {
            padding-bottom: 50px !important;
        }
        &.small-bottom-padding-100 {
            padding-bottom: 100px !important;
        }
        &.small-bottom-padding-200 {
            padding-bottom: 200px !important;
        }
    }


    @media (max-width: 430px) {
        .tpl-content .tpl-title {
            font-size: 26px;
        }
    }
}

/*==========================================================================*/
/* Global Featured view - End  =============================================*/
/*==========================================================================*/