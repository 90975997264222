@import "variables";

.tpl-position-hero-header {
    position: relative;
    z-index: 11;
    .tpl-hero-header-left {
        left: 50px;
        display: inline-block;
        float: left;
        width: 120px;
        position: absolute;
        z-index: 2;
        top: 20px;
        @media (max-width: 900px) {
            left: 20px;
        }
    }
}

.tpl-body-content {
    position: relative;
    right: 0;
    overflow-x: hidden;
    transition: right 0.3s ease;
    &.open-flyout {
        right: $flyout-width;
    }
}


.tpl-page {
    position: relative;
    left: 0;
    height: 100%;
    overflow-x: hidden;
    transition: left 0.3s ease;
    //padding-top: $header-height;
}

/*==========================================================================*/
/* Flyout ==================================================================*/
/*==========================================================================*/
.tpl-position-flyout {
    transition: all 0.3s ease;
    width: $flyout-width;
    background: $color-white;
    right: -$flyout-width;
    position: fixed;
    height: 100%;
    top: 0;
    z-index: 997;

    .tpl-position-flyout-inner {
        position: relative;
        padding: 15px 30px 30px;
        overflow: auto;
        height: 100%;

        .tpl-mobile-menu-close-trigger {
            @include tpl-close-icon("absolute");
            color: $color-dark;
            top: 26px;
            right: 24px;
        }
    }
}

/*==========================================================================*/
/* Header ==================================================================*/
/*==========================================================================*/
.tpl-position-header {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 100;

    .tpl-position-header-inner {
        position: relative;
        padding: 25px 80px;
        width: 100%;
        display: table;
        vertical-align: middle;
    }

    .tpl-position-header-search {
        background: $color-dark;

        .tpl-position-header-search-inner {
            position: relative;
            height: $header-search-height;
            width: 100%;

            .tpl-search-close-trigger {
                @include tpl-close-icon("absolute-vertical");
            }
        }
    }

    .tpl-position-header-left {
        width: 30%;
        display: table-cell;
        vertical-align: middle;
        
        .tpl-image img {
            max-height: 100px;
            // max-width: 400px;
        }
    }

    .tpl-position-header-right {
        width: 70%;
        position: relative;
        text-align: right;
        display: table-cell;
        vertical-align: middle;

        .tpl-position-header-right-inner {}

        .tpl-section-user-menu {
            position: relative;
            float: right;
            height: 100%;

            .tpl-position-user-menu {
                height: 100%;
            }

            .tpl-user-content {
                position: absolute;
                top: $header-height;
                left: -20px;
                width: 220px;
                z-index: 1001;
                background: $color-dark;
                color: #fff;
                visibility: hidden;
                opacity: 0;
                padding: 24px 25px 20px;

                &.visible {
                    visibility: visible;
                    opacity: 1;
                }

                .tpl-logged-in-message {
                    display: none;
                    font-weight: 700;
                    font-size: 20px;
                    padding-bottom: 10px;
                }
            }
        }

        .tpl-search-trigger {
            width: 20px;
            right: 83px;

            &:after {
                content: $tpl-icon-li-search;
            }
        }

        .tpl-header-trigger.tpl-mobile-menu-trigger {
            display: none;
        }
    }
    // When header becomes fixed to the top of the page
    &.fixed {
        position: fixed;
        top: 0;
        background: #fff;
        z-index: 998;
        right: 0;
        width: auto;
        // When flyout menu is opened, and it moves header to the right
        &.open-flyout {
            right: $flyout-width;
            left: -$flyout-width;
        }
    }
    // margin-animation class is added to header by jQuery, with small delay (around 0.2 sec, after first header animation ends.)
    // onload-top is class added to header on pages where there is no hero above it.
    &.margin-animation,
    &.onload-top {
        transition: all 0.3s ease;
    }
}

/*==========================================================================*/
/* Footer ==================================================================*/
/*==========================================================================*/
.tpl-position-footer {
    background: $color-gray;
    color: $color-white;
    padding: 80px 0;
    font-size: 11px;
    text-transform: uppercase;
    position: relative;
    
    .tpl-back-to-top {
        position: absolute;
        width: 50px;
        height: 50px;
        top: -25px;
        left:50%;
        margin-left: -25px;
        background-color: $color-white;
        border-radius: 50%;
        font-size: 12px;
        font-weight: 600;
        color: $color-dark;
        text-transform: uppercase;
        text-align: center;
        line-height: 65px;
        cursor: pointer;
        
        &:before {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            content: '\f106';
            font-family: $font-family-icons-fa;
            color: $color-dark;
            font-size: 32px;
            line-height: 1;
        }
    }
}

@media only screen and (max-width: $breakpoint-header) {
    .tpl-position-header {

        .tpl-position-header-inner {
            padding-left: 3%;
            padding-right: 3%;
        }

        .tpl-position-header-left {
            width: 80%;

            .tpl-position-header-left-inner {
                padding-right: 20px;
            }
            .tpl-image {
                display: block;
                max-width: 260px;
            }
        }

        .tpl-position-header-right {
            width: 20%;
            
            .tpl-section-user-menu .tpl-user-content {
                top: $header-height-responsive;
                left: auto;
                right: 0 ;
                margin-left: 0;
            }
            .tpl-position-header-right-inner {
                padding-right: 30px;
                
                .tpl-menus-menu-header-menu {
                    display: none;
                }
            }
            
            .tpl-header-trigger.tpl-mobile-menu-trigger {
                display: block;
            }
        }
    }
}

@media only screen and (max-width: 630px) {
    .tpl-position-header .tpl-position-header-inner {
        padding: 20px;
    }

    .tpl-position-header {
        .tpl-position-header-left {
            width: 70%;
        }
        .tpl-position-header-right {
            width: 30%;
        }
    }
}

@media only screen and (max-width: 360px) {
    .tpl-position-header {
        .tpl-position-header-right {
            padding-right: 30px;

            .tpl-search-trigger {
                right: 30px;
            }
        }
    }
}


@media print {
    .tpl-page {
        background: $color-white;
    }
}