// Breakpoints
$breakpoint-header: 1024px;

$header-height: 150px;
$header-height-responsive: 60px;
$header-search-height: 50px;

$hero-height: 50vh;
$hero-height-big: 560px;

$default-content-padding: 20px; // 1.429rem
$default-content-padding-sides: 10px;

$flyout-width: 270px;


// Colors
$color-dark: #272727;
$color-dark-blue: #225b81;
$color-blue: #6598b8;
$color-light-blue: rgba(101, 152, 184, .7);
$color-green: #738b53;
$color-gray: #808080;
$color-light-7d: #7d7d7d; // field placeholder, "no file selected" in advocacy costumize, advocacy top and bottom title border
$color-light-96: #96979A; // border as separator, borders in Featured Project view,
$color-light-a7: #A79E9A; // field icons
$color-light-db: #DBD9D9; // borders, ext grid row bg, empty image background
$color-light-e5: #e5e1db;
$color-light-ec: #ece9e3;
$color-light-e8: #E8E7E7; // header bottom border
$color-light-fa: #faf5f5; // header menu color
$color-light: #f2f0ec; // content background
$color-white: #fff;
$color-black: #000;
$color-red: #ff0033;
$color-facebook: #2b5893;
$color-twitter: #1da7d3;
$color-google: #cf5038;
$color-pinterest: #ca2f28;

$color-error: #BA1126;
$color-link: $color-blue; // Links that are not using this color: Advocacy Promote "No Thanks" link, advocacy review title


$color-task-type-standard: #f39c12;
$color-task-type-message: #27ae60;
$color-task-type-form: #8e44ad;
$color-task-type-question: #ad2134;


$color-tag-label: #4F3D35;
$color-tag: #91CCE1;

$color-highlight: $color-light-blue;

$border-width: 1px;
$border-color: $color-light-db;
$border-radius: 0;


// Fonts
$font-family: 'brandon-grotesque', sans-serif;
$font-family-icons-fa: 'FontAwesome';

$tpl-icon-li-search: "\e922";
$tpl-icon-li-calendar: "\e789";
$tpl-icon-li-edit: "\e612";
$tpl-icon-li-x: "\e92a";
$tpl-icon-fa-arrow-down: "\f107";
$tpl-icon-fa-arrow-up: "\f106";
$tpl-fa-icon-arrow-thick-down: "\f078";
$tpl-fa-icon-arrow-thick-up: "\f077";
$tpl-fa-icon-arrow-thick-right: "\f054";
$tpl-fa-icon-arrow-thick-left: "\f053";

$font-size: 14px;
$font-size-small: 12px;
$font-size-large: 18px;


// Input fields
$field-height: 60px;
$field-height-small: 45px;
$field-height-smaller: 40px;
$field-background-color: $color-white;
$field-color: $color-black;
$field-icon-color: $color-light-a7;
$field-font-size: 17px;
$field-font-size-smaller: 14px;
$field-font-weight: 500;
$field-border-color: $field-background-color; // $border-color;
$field-border-width: $border-width;
$field-border: $field-border-width solid $field-border-color;
$field-border-radius: $border-radius;
$field-padding: 10px 18px;
$field-padding-smaller: 5px 14px;


$dropdown-items-vertical-padding: 15px;
$dropdown-items-vertical-padding-smaller: 12px;



// Buttons
$button-font-size: 18px;
$button-font-size-small: 13px;
$button-border-width: 2px;
$button-border-color: $color-dark;
$button-color: $color-dark;
$button-background-color: transparent;
$button-font-weight: 700;
$button-padding: 0 24px;
$button-padding-small: 0 20px;
$button-padding-smaller: 0 18px;


$tpl-simple-form-button-width: 90px;
$tpl-simple-form-button-width-small: 80px;

$tpl-share-icon-size: 32px;

$default-animation-duration: .3s !default;

@mixin tpl-default-transition($animate: all, $duration: $default-animation-duration, $animation: ease-in-out, $delay: 0s){
    transition: $animate $duration $animation $delay;
}

/* popups */
$header-title-background: $color-dark;
$header-title-color: $color-white;
$color-overlay: rgba(0,0,0,0.4) !default;
$color-active-state: $color-gray;
$z-index-popups: 500 !default;
$z-index-popup-close-icon: 550 !default;

/* end popups */


/* From /fonts/Linearicons/style.css */
@mixin tpl-linear-icon($content: false) {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Linearicons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    font-feature-settings: "liga";
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $content != false {
        content: $content;
    }
}


// Popups with custom title
$popup-title-height: 60px;

@mixin tpl-custom-popup-title($title, $responsive-title:$title, $width: 560px) {
    height: $popup-title-height;
    font-family: $font-family;
    background: $color-dark;
    padding: 13px 20px 0;
    text-align: center;
    overflow: hidden;
    font-size: 0;
    float: none;
    &:before {
        color: $color-white;
        content: $title;
        font-size: 28px;
        font-weight: 300;
        display: block;
        width: 100%;
        @media (max-width: $width) {
            content: $responsive-title;
        }
    }
}


@mixin tpl-centered-content($max-width: 1150px, $without-side-padding: false) {
    max-width: $max-width + (2 * $default-content-padding);
    padding: 0 $default-content-padding;
    margin: 0 auto;
    width: 100%;

    &.tpl-centered-without-side-padding {
        max-width: $max-width;
        padding-left: 0;
        padding-right: 0;
    }

    @if $without-side-padding == true {
        max-width: $max-width;
        padding-left: 0;
        padding-right: 0;
    }
}


@mixin tpl-cancel-browser-style() {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-appearance: none !important;
}

@function tpl-hover-lighten($color){
    @return lighten($color, 5%);
}

@mixin tpl-disabled() {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: auto;
}

@mixin tpl-full-spread($position: absolute, $background-color: false){
    position: $position;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    @if $background-color != false {
        @if $background-color == true {
            background-color: rgba(0,0,0,0.3);
        } @else {
            background-color: $background-color;
        }
    }
}

$tpl-close-icon-size: 18px;

@mixin tpl-close-icon($position: "static") {
    cursor: pointer;
    width: $tpl-close-icon-size;
    height: $tpl-close-icon-size;
    line-height: $tpl-close-icon-size;
    vertical-align: middle;
    color: $color-white;
    font-size: $tpl-close-icon-size;

    @if $position == "absolute" {
        position: absolute;
        right: 15px;
        top: 15px;
    }

    @if $position == "absolute-vertical" {
        position: absolute;
        top: 50%;
        right: $default-content-padding;
        margin-top: -$tpl-close-icon-size/2;
    }

    &:after {
        @include tpl-linear-icon("\e92a");
    }
}


@mixin tpl-background-image-2x($image, $type: "png", $width: 100%, $height: 100%) {
    background-image: url($image + '.' + $type);

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-moz-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx){
        & {
            background-image: url($image + '@2x.' + $type);
            -webkit-background-size: $width $height;
            -moz-background-size: $width $height;
            -o-background-size: $width $height;
            background-size: $width $height;
        }
    }
}

@mixin tpl-skew($angle: 0deg) {
    -webkit-transform: skew($angle);
    -moz-transform: skew($angle);
    -o-transform: skew($angle);
}


$font-size-h1: 32px;
$font-size-h2: 18px;
$font-size-h3: 11px;
$font-size-h4: 14px;
$font-size-h5: 12px;
$font-size-h6: 10px;


@mixin tpl-heading($i: false, $font-size-scale: 1) {
    font-family: $font-family;
    font-weight: 400;
    color: $color-dark;
    padding: 0;
    margin: 0;
    letter-spacing: 0;
    line-height: 1.2em;

    @if $i == 1 {
        font-size: ceil($font-size-h1 * $font-size-scale);
    }
    @if $i == 2 {
        font-size: ceil($font-size-h2 * $font-size-scale);
        letter-spacing: 0.4px;
    }
    @if $i == 3 {
        font-size: ceil($font-size-h3 * $font-size-scale);
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 1.6em;
    }
    @if $i == 4 {
        font-size: ceil($font-size-h4 * $font-size-scale);
        font-weight: bold;
    }
    @if $i == 5 {
        font-size: ceil($font-size-h5 * $font-size-scale);
        font-weight: bold;
    }
    @if $i == 6 {
        font-size: ceil($font-size-h6 * $font-size-scale);
        font-weight: bold;
    }
}

@mixin tpl-module-title() {
    @include tpl-heading(3);
    text-transform: none;
}


@mixin tpl-body() {
    font-size: $font-size;
    font-weight: 300;
    line-height: 1.6;
}

@mixin tpl-link() {
    font-weight: 700;
    color: $color-link;
    outline: none;
    text-decoration: none;

    &:hover {
        color: $color-light-blue;
    }
}

@mixin tpl-menu-link-color() {
    color: $color-white;
    opacity: 0.6;
    transition: color .3s ease;

    &:hover {
        opacity: 1;
    }
}



@mixin tpl-button-base() {
    display: inline-block;
    font-family: $font-family;
    font-size: $button-font-size;
    border: $button-border-width solid $button-border-color;
    border-radius: $field-border-radius;
    box-shadow: none;
    text-shadow: none;
    text-transform: uppercase;
    font-weight: $button-font-weight;
    color: $button-color;
    background: $button-background-color;
    cursor: pointer;
    text-align: center;
    height: $field-height;
    line-height: $field-height - 3px;
    overflow: hidden;
    @include tpl-cancel-browser-style;
    padding: $button-padding;
}

@mixin tpl-button-base-small() {
    @include tpl-button-base;
    height: $field-height-small;
    line-height: $field-height-small - 3px;
    font-size: $button-font-size-small;
    padding: $button-padding-small;
}

@mixin tpl-button-base-smaller() {
    height: $field-height-smaller;
    line-height: $field-height-smaller - 3px;
    font-size: $button-font-size-small;
    padding: $button-padding-smaller;
}


// If $color: false, basic button style is black & white
//
// $color - string (hex, rgb, rgba)
// $solid - bool
// $size - string ("normal", "small", "smaller", "small-auto")
@mixin tpl-button($color: $color-gray, $solid: false, $size: "normal") {
    @include tpl-button-base;
    color: $color;
    border-color: $color;

    &:hover {
        background-color: $color;
        color: $color-white;
    }

    @if $solid == true {
        background-color: $color;
        border-color: $color;
        color: $color-white;

        &:hover {
            border-color: tpl-hover-lighten($color);
            background-color: tpl-hover-lighten($color);
            color: $color-white;
        }
    }

    @if $size == "small" {
        @include tpl-button-base-small;
    }

    @if $size == "smaller" {
        @include tpl-button-base-smaller;
    }

    @if $size == "small-auto" {
        @include tpl-button-base-small;
        height: auto;
        line-height: normal;
        padding: 8px 14px;
    }

    @if $color == $color-white {
        &:hover {
            color: $color-dark;
        }
    }
}



@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}


@mixin tpl-input-field-placeholder() {
    font-weight: 300;
    color: $color-light-7d;
}

@mixin tpl-input-field($height:"default", $border: false) {
    width: 100%;
    font-family: $font-family;
    font-size: $field-font-size;
    font-weight: $field-font-weight;
    height: $field-height;
    background-color: $field-background-color;
    color: $field-color;
    border: $field-border;
    border-radius: $field-border-radius;
    @include tpl-cancel-browser-style;
    padding: $field-padding;
    resize: vertical;

    @include input-placeholder {
        @include tpl-input-field-placeholder;
    }

    &:disabled,
    .disabled,
    &[disabled=disabled] {
        @include tpl-disabled;
    }

    @if $height == "small" {
        height: $field-height-small;
    }

    @if $height == "smaller" {
        height: $field-height-smaller;
        font-size: $field-font-size-smaller;
        padding: $field-padding-smaller;
    }

    @if $border == true {
        border: $field-border-width solid $border-color;
    }
}

@mixin tpl-input-field-select($img_prefix: "../") {
    background-image: url('#{$img_prefix}img/arrow-gray-down.png');
    background-repeat: no-repeat;
    background-position: center right 20px;
    @include tpl-cancel-browser-style;
    padding-right: 40px;
}

@mixin tpl-input-field-textarea() {
    min-height: 100px;
    height: auto;
    line-height: normal;
    padding-top: 14px;
    padding-bottom: 14px;
}

@mixin tpl-field-with-icon-holder(){
    position: relative;
}
@mixin tpl-field-with-icon-holder-after($icon, $font: "Linearicons") {
    @if $font == "Linearicons" {
        @include tpl-linear-icon;
    } @else {
        font-family: $font;
    }
    position: absolute;
    content: $icon;
    color: $field-icon-color;
    font-size: 16px;
    height: 16px;
    line-height: 16px;
    vertical-align: middle;
    top: 50%;
    right: 16px;
    z-index: 0;
    margin-top: -8px;
}
@mixin tpl-field-with-icon(){
    position: relative;
    background: none;
    z-index: 1;
    padding-right: 35px;
}

@mixin tpl-input-field-holder-icon($icon: false, $font: "Linearicons") {
    @if $icon != false {
        @include tpl-field-with-icon-holder;

        &:after {
            @include tpl-field-with-icon-holder-after($icon, $font)
        }

        input[type=text] {
            @include tpl-field-with-icon();
        }
    }
}


// Checkbox and Radio
$checkbox-radio-color: $border-color;
$checkbox-radio-color-active: $color-blue;
$checkbox-radio-border-width: 1px;
$checkbox-radio-border: $checkbox-radio-border-width solid $checkbox-radio-color;
$checkbox-radio-size: 22px;


@mixin tpl-checkbox-radio-field() {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
@mixin tpl-checkbox-radio-label() {
    position: relative;
    line-height: $checkbox-radio-size;
    text-align: left;
    width: auto;
    display: inline-block;
    font-size: $font-size;
    font-weight: 300;
    color: $color-dark;
    padding: 0 0 3px $checkbox-radio-size+6px;
}
@mixin tpl-checkbox-radio($on-white-background: false, $position: false) {
    content: " ";
    display: inline-block;
    border: $checkbox-radio-border;
    width: $checkbox-radio-size;
    height: $checkbox-radio-size;
    line-height: $checkbox-radio-size;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    background: none;

    @if $on-white-background == true {
        background: $color-white;
    }

    @if $position == "absolute-vertical" {
        position: absolute;
        top: 50%;
        left: 0;
        vertical-align: middle;
        margin-top: -($checkbox-radio-size / 2) - 2;
    }
}
@mixin tpl-checkbox-radio-hovered() {}

// Checkbox
@mixin tpl-checkbox() {
    border: $checkbox-radio-border;
    border-radius: 1px;
}
@mixin tpl-checkbox-checked() {
    border: 4px solid $checkbox-radio-color-active;
    background: $checkbox-radio-color-active;
    color: #fff;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
}
// Radio
@mixin tpl-radio($on-white-background: false) {
    border: $checkbox-radio-border;
    border-radius: 50%;

    @if $on-white-background == true {
        border-color: transparent;
    }
}
@mixin tpl-radio-checked($aligned: "center") {
    background-color: transparent;
    border-color: $checkbox-radio-color;

    &:after {
        content: "";
        display: block;
        border-radius: 50%;
        background: $checkbox-radio-color-active;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 10px;
        height: 10px;
        margin: -4px 0 0 -5px;
    }

    @if $aligned == "left" {
        &:after {
            left: 6px;
            margin-left: 0;
            margin-top: -7px;
        }
    }
}

@mixin tpl-icon-x($position: false, $size: 20px, $weight-in-px: 2px, $color: $color-white, $animate: false) {
    background: none;
    cursor: pointer;
    position: relative;
    width: $size;
    height: $size;
    @include tpl-default-transition;

    @if $position == "absolute" {
        position: absolute;
        right: 15px;
        top: 15px;
    }
    @if $position == "absolute-vertical" {
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -$size/2;
    }

    &:before,
    &:after {
        background: $color;
        width: $size;
        margin-top: $size / 2;
        content: '';
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        height: $weight-in-px;
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(135deg);
    }

    @if $animate == true {
        &:hover {
            color: $color-white;
            transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
        }
    }
}

@mixin tpl-popup-title() {
    @include tpl-heading(6);
    background-color: $header-title-background;
    color: $header-title-color;
    position: relative;
    line-height: 1.2;
    padding: 18px 46px 16px 22px;
    margin: 0;
    width: 100%;
}