@import "variables";

html, body {
    margin: 0;
    padding: 0;
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: $font-size;
    overflow-x: hidden;
    height: 100%;
}

body {
    font-family: $font-family;
    font-size: $font-size;
    line-height: normal;
    color: $color-dark;
    margin: 0;
    height: auto;
    letter-spacing: 1px;
}
.x-body {
    color: $color-dark;
}

body *,
body *:after,
body *:before {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.tpl-body,
.tpl-summary {
    @include tpl-body;
}

a {
    @include tpl-link;
}

a img {
    border: none;
    outline: none;
    vertical-align: top;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

.clearfix:after {
    clear: both;
    content: ".";
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
}

.tpl-loading-image {
    width: 100%;
    min-height: 100px;
    background: url('../img/ajax-loader.gif') 50% 50% no-repeat;
}


.tpl-image-no .tpl-image-holder {
    display: inline-block;
    position: relative;
    background-color: $color-light-db;

    img {
        opacity: 0;
    }

    &:after {
        @include tpl-linear-icon("\e70e");
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        color: $color-gray;
        width: 60px;
        height: 60px;
        font-size: 60px;
        line-height: 1;
        margin: -30px 0 0 -30px;
    }
}

.tpl-background-image {
    background-color: $color-light-blue;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
    &.skrollable {
        background-attachment: fixed;
    }
}

.tpl-hero {
    position: relative;
    overflow: hidden;
    @extend .tpl-background-image;

    .tpl-video-header {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
    }

    .tpl-hero-content {
        .tpl-title {
            font-size: 55px;
            font-weight: 300;
            text-transform: uppercase;
        }
        position: relative;
        z-index: 4;
    }

    .tpl-hero-overlay {
        @include tpl-full-spread;
        z-index: 3;
    }

    .tpl-stripes-holder {
        @include tpl-full-spread;
        z-index: 2;

        svg {
            width: 100%;
            height: 100%;
        }

        .tpl-stripes {
            fill: $color-dark;
        }
    }

    .tpl-video-player-holder {
        @include tpl-full-spread;
        overflow: hidden; // to cut out video
        z-index: 1; // has lowest z-index so all other content would show above
    }
    
    .tpl-scroll-arrow {
        display: none;
        position: absolute;
        width: 150px;
        height: 30px;
        bottom: 24px;
        left: 50%;
        margin-left: -75px;
        z-index: 5;
        cursor: pointer;
        
        &:before {
            font-family: $font-family-icons-fa;
            color: #fff;
            content: "\f107";
            font-size: 50px;
            line-height: 30px;
        }
        
        &:hover:before {
            display: none;
        }

        &:hover span {
            display: block;
        }
        
        span {
            display: none;
            height: 100%;
            font-size: 12px;
            color: $color-white;
            letter-spacing: 2px;
            text-align: center;
            line-height: 30px;
            text-transform: uppercase;
        }
    }


    // Hero height
    .tpl-hero-content,
    .tpl-video-player {
        height: $hero-height;
    }

    // Bigger hero height
    &.tpl-hero-larger {
        .tpl-hero-content,
        .tpl-video-player {
            height: $hero-height-big;
        }
    }

    // In Hero Header
    &.tpl-full-height {
    //.tpl-position-hero-header & {
        .tpl-scroll-arrow {
            display: block;
        }
        
        .tpl-video-player,
        .tpl-hero-content {
            height: 100vh;
        }
    }

    @media (max-width: 800px) {
        // Hero height
        .tpl-hero-content,
        .tpl-video-player {
            height: 380px;
        }

        // Bigger hero height
        &.tpl-hero-larger {
            .tpl-hero-content,
            .tpl-video-player {
                height: 420px;
            }
        }

        // In Hero Header
        &.tpl-full-height {
            .tpl-video-player,
            .tpl-hero-content {
                height: 100vh;
            }
        }
    }
}

.tpl-table {
    display: table;
    width: 100%;
    height: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    .tpl-cell {
        display: table-cell;
        width: 100%;
        height: 100%;
        text-align: center;
        vertical-align: middle;
    }
}


iframe {
    max-width: 100%;
}

.tpl-video-holder {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.lnr {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
}

.tpl-text-align-center {
    text-align: center;
}

.tpl-hide {
    display: none;
}

.tpl-border-top {
    border-top: $border-width solid $border-color;
}
.tpl-border-bottom {
    border-bottom: $border-width solid $border-color;
}


// Headings H1-H6
@for $i from 1 through 6 {
    h#{$i}, .tpl-h#{$i} {
        @include tpl-heading($i);
    }
}



input[type=text],
input[type=email],
input[type=password],
input[type=number],
input[type=numeric],
input[type=card],
input[type=phone],
input[type=file],
select,
textarea {
    @include tpl-input-field;
}
select,
.form-input-wrapper.multiselect input[type="text"] {
    @include tpl-input-field-select;
}
textarea {
    @include tpl-input-field-textarea;
}

input.x-boxselect-input-field {
    height: auto;
    padding: 0;
}
/* Fields with icons (Select/Dropdown, Search, Date, etc.) */
.tpl-input-search-holder,
.input-search-holder,
.tpl-input-datepicker-holder,
.input-datepicker-holder,
.tpl-select-field-holder,
.select-field-holder,
.field-holder-error.form-input-holder {
    @include tpl-field-with-icon-holder();
}
.tpl-input-search-holder,
.input-search-holder {
    &:after {
        @include tpl-field-with-icon-holder-after($tpl-icon-li-search);
    }
    .clear:after {
        @include tpl-field-with-icon-holder-after($tpl-icon-li-x);
        font-size: 16px;
        z-index: 2;
    }
}

.tpl-input-datepicker-holder:after,
.input-datepicker-holder:after {
    @include tpl-field-with-icon-holder-after($tpl-icon-li-calendar);
}

.tpl-select-field-holder:after,
.select-field-holder:after {
    @include tpl-field-with-icon-holder-after($tpl-icon-fa-arrow-down, $font-family-icons-fa);
    //  font-size: 24px;
}

//.field-holder-error.form-input-holder:after {
//    @include tpl-field-with-icon-holder-after($tpl-fa-icon-warning, $font-family-icons-fa);
//    color: $color-error;
//}

.tpl-input-search-holder .search,
.tpl-input-search-holder input[type=text],
.input-search-holder .search,
.input-search-holder input[type=text],
.tpl-input-datepicker-holder .datepicker,
.tpl-input-datepicker-holder input[type=text],
.input-datepicker-holder .datepicker,
.input-datepicker-holder input[type=text],
.tpl-select-field-holder select,
.select-field-holder select,
.field-holder-error.form-input-holder input[type=text]{
    @include tpl-field-with-icon();
}


.tpl-select-field-holder select,
.select-field-holder select {
    -webkit-appearance: none;
    -moz-appearance: none !important;
}

form .ajax-error,
.form-error,
.form-input-error span {
    color: $color-error;
}

.form-input-holder .error,
.form-input-error {
    font-weight: 300;
    padding: 3px 0 0 3px;
}

// Dropdown input field style
// Dropdown holder
.ui.selection.dropdown {
    border: $field-border;
    min-height: $field-height;
    line-height:normal;
    border-radius: $border-radius;
    &:focus {
        border-color: $color-blue;
    }
    &.upward.selection,
    &.active.upward {
        border-radius: $border-radius !important;
    }
}

// Dropdown Icon
.ui.selection.dropdown > .dropdown.icon {
    padding-top: 13px;
}
.ui.dropdown > .dropdown.icon:before {
    content: '\f078';
    font-family: $font-family-icons-fa;
}

// Dropdown Text
.ui.search.dropdown > .text {
    //padding-top: 3px;
}

// Dropdown Menu
.ui.selection.dropdown .menu {
    border-radius: 0 0 1px 1px;
    margin: $field-border-width -#{$field-border-width};
    min-width: calc(100% + #{2 * $field-border-width});
    width: calc(100% + #{2 * $field-border-width});
}
.ui.selection.dropdown.upward .menu {
    border-radius: 1px 1px 0 0;
}

.ui.selection.active.dropdown .menu {
    box-shadow: none;
}

.ui.selection.active.dropdown:hover .menu {
    border: $field-border-width solid $color-blue;
}

// Active Dropdown Holder and Dropdown Menu
.ui.selection.active.dropdown,
.ui.selection.active.dropdown .menu {
    &, &:hover {
        border: $field-border-width solid $color-highlight;
    }
}

// Dropdown Menu Item
.ui.selection.dropdown .menu > .item,
.ui.selection.active.dropdown .menu .item {
    padding-top: $dropdown-items-vertical-padding-smaller !important;
    padding-bottom: $dropdown-items-vertical-padding-smaller !important;
    border: none;
    &:hover {
        background-color: $color-highlight;
    }
}

.ui.dropdown .menu .selected.item {
    background: darken($color-highlight, 10%);
    color: #fff;
    border: none;
}


.form-input-holder {
    margin: 5px 0;

    .combo-options {
        border-color: $field-border-color;
        margin-top: -5px;

        .records .option {
            padding: $field-padding;
            padding-top: 6px;
            padding-bottom: 6px;

            &.focused {
                background-color: $color-highlight;
            }
        }
    }
}


/* Custom checkbox / radio buttons (iCheck) everything else is in custom skin (green) */
.custom-radio,
.custom-checkbox {
    margin-top: -2px;
}
.custom-checkbox {
    @include tpl-checkbox-radio;
    @include tpl-checkbox;

    &:hover {
        @include tpl-checkbox-radio-hovered;
    }
    &.checked {
        @include tpl-checkbox-checked;
    }
}

/*==========================================================================*/
/* Share Icons - Start =====================================================*/
/*==========================================================================*/
.tpl-elements-social-share {
    a {
        position: relative;
        display: inline-block;
        width: $tpl-share-icon-size;
        height: $tpl-share-icon-size;
        border-radius: 50%;
        border: 1px solid $color-dark;
        color: $color-dark;

        &:before {
            position: absolute;
            display: block;
            width: 100%;
            height: $tpl-share-icon-size;
            line-height: $tpl-share-icon-size;
            vertical-align: middle;
            top: 0;
            left: 0;
            text-align: center;
            font-weight: 400;
            font-family: $font-family-icons-fa;
            font-size: 17px;
            content: '\f056';
        }

        &:hover {
            box-shadow: inset 0 $tpl-share-icon-size 0 $color-dark;
            background: $color-dark;
            color: $color-white;
        }

        &.tpl-twitter {
            margin-right: 5px;

            &:before {
                content: '\f099';
            }
        }
        &.tpl-facebook:before {
            content: '\f09a';
        }
    }

    &.tpl-light a {
        border-color: $color-white;
        color: $color-white;

        &:hover {
            box-shadow: inset 0 $tpl-share-icon-size 0 $color-white;
            background: $color-white;
            color: $color-dark;
        }
    }
}
/*==========================================================================*/
/* Share Icons - End =======================================================*/
/*==========================================================================*/

/*==========================================================================*/
/* Advocacy Details View - Start ===========================================*/
/*==========================================================================*/
.tpl-advocacy-details {
    padding: 40px 0;

    .tpl-image-holder {
        float: right;
        width: 35%;
        max-width: 600px;
        padding: 0 0 10px;

        img {
            max-width: 100%;
        }
    }
    .tpl-body {
        float: left;
        width: 65%;
        font-size: 18px;
        padding-right: 20px;
    }
}

@media (max-width: 600px) {
    .tpl-advocacy-details {
        text-align: center;
        padding: 30px 0;

        .tpl-image-holder,
        .tpl-body {
            float: none;
            width: 100%;
            padding: 0;
            margin: 5px auto 20px;
        }
    }
}
/*==========================================================================*/
/* Advocacy Details View - End =============================================*/
/*==========================================================================*/




/*==========================================================================*/
/* Advocacy Review View - Start ============================================*/
/*==========================================================================*/

.tpl-advocacy-review {
    .tpl-advocacy-page-title,
    .tpl-advocacy-page-footer {
        padding: 15px 0;

        .tpl-advocacy-action-button {
            @include tpl-button($size: small);
            font-size: 13px;
            font-weight: 600;
        }
    }

    .tpl-advocacy-page-title {
        h3 {
            float: left;
            width: 60%;
            line-height: 1.1;
            margin: 10px 0;

            a {
                @include tpl-heading(3);
                color: $color-dark;
                text-decoration: none;
            }
        }
        .tpl-advocacy-action-button {
            float: right;
            margin: 6px 0;
        }
    }

    .tpl-campaign-details {
        padding: 30px 0;

        .tpl-image-holder {
            float: left;
            width: 32%;
            padding: 0;
            margin: 20px 0;

            img {
                max-width: 100%;
            }
        }
        .tpl-content-holder {
            float: left;
            width: 68%;
            padding-left: 38px;
            margin: 15px 0;

            .tpl-body {
                font-size: 18px;
                padding-right: 15px;
            }
            .tpl-funding-wrapper {
                margin: 48px 0 10px;
                .tpl-funding-total {
                    float: right;
                }
            }
        }
    }

    .tpl-advocacy-page-footer {
        a.tpl-advocacy-go-back-button {
            @include tpl-button($size:"small");
            padding: 0 16px;
            margin: 6px 15px 6px 0;
        }
        a.tpl-advocacy-action-button {
            float: right;
            margin: 6px 0;
        }
    }
}

@media (max-width: 800px) {
    .tpl-advocacy-review {
        text-align: center;

        // Title and button
        .tpl-advocacy-page-title {
            width: 100%;
            h3,
            .tpl-advocacy-action-button {
                float: none;
                margin: 10px auto;
            }
            h3 {
                width: 100%;
            }
            .tpl-advocacy-action-button {
                max-width: 110px;
            }
        }

        // Details and image
        .tpl-campaign-details {
            max-width: 600px;
            padding: 20px 0;
            margin: 0 auto;

            .tpl-image-holder,
            .tpl-content-holder {
                float: none;
                width: 100%;
                padding: 0;
            }

            .tpl-content-holder {
                .tpl-body {
                    padding-right: 0;
                }
                .tpl-funding-wrapper {
                    text-align: left;
                }
            }
        }

        // Footer
        .tpl-advocacy-page-footer {
            text-align: center;

            a.tpl-advocacy-action-button {
                float: none;
                max-width: 110px;
                margin: 10px auto;
            }
        }
    }
}
/*==========================================================================*/
/* Advocacy Review View - End ==============================================*/
/*==========================================================================*/


/*==========================================================================*/
/* Bootstrap adjustments - Start ===========================================*/
/*==========================================================================*/

// Checkbox and Radio
.c-indicator {
    @include tpl-checkbox-radio;
}

// Checkbox
.c-checkbox {
    margin-bottom: 0;

    .c-label {
        vertical-align: middle;
        margin-left: 5px;
    }

    .c-indicator {
        @include tpl-checkbox;
    }
    &:hover .c-indicator {
        @include tpl-checkbox-radio-hovered;
    }

    & > input:checked ~ .c-indicator {
        @include tpl-checkbox-checked;
    }
}

// Radio
.c-radio {
    .c-indicator{
        @include tpl-radio;
    }
    &:hover .c-indicator {
        @include tpl-checkbox-radio-hovered;
    }

    & > input:checked ~ .c-indicator {
        @include tpl-radio-checked;
    }
}



.btn-primary,
.btn-default {
    &, &:focus, &.focus, &:active, &.active, &.dropdown-toggle, .open > &, .open > &.dropdown-toggle {
        @include tpl-button($size: "smaller");

        &:hover {
            border-color: $color-dark;
        }
    }
}
.btn-primary.disabled:hover,
.btn-primary:disabled:hover,
fieldset[disabled] .btn-primary:hover {
    @include tpl-button($size: "smaller");
    @include tpl-disabled;
}
a.btn.disaabled,
fieldset[disabled] a.btn {
    pointer-events: auto;
}

.dropdown-toggle {
    .caret {
        display: none;
    }
    &:after {
        display: inline-block;
        font-family: $font-family-icons-fa;
        content: $tpl-icon-fa-arrow-down;
        color: $color-dark;
        font-size: 16px;
        width: auto;
        height: auto;
        border: none;
        vertical-align: middle;
        margin: -2px 0 0 5px;
    }

    &:hover {
        &:after {
            color: #fff;
        }
    }
}

.btn-dark,
.btn-dark:hover,
.btn-dark:focus {
    background-color: $color-dark;
    border-color: $color-dark;
    color: #fff;

    &.dropdown-toggle:after {
        color: #fff;
    }
}

.btn.disabled,
.btn:disabled,
fieldset[disabled] .btn {
    @include tpl-disabled;
}

// Dropdown menu - holder / popup
.dropdown-menu {
    box-shadow: none;
    border: 1px solid rgba(0,0,0,.06);
    border-radius: $border-radius;
    min-width: 170px;
    max-height: 370px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    margin: 8px 0;
}

// Dropdown menu - item
.dropdown-item,
.btn-group .dropdown-menu .dropdown-item,
.ui.dropdown .menu > .item,
.processes-modules-task-list.default .filter-buttons .filters-wrapper-workflow .dropdown-menu .li a {
    font-size: 13px;
    line-height: normal;
    padding: $dropdown-items-vertical-padding 18px !important; /* !important because of .ui.dropdown .menu > .item */
}
.dropdown-item,
.ui.dropdown .menu > .item,
.processes-modules-task-list.default .filter-buttons .filters-wrapper-workflow .dropdown-menu .li a {
    font-weight: 600;
    color: $color-gray;
    cursor: pointer;

    &:focus,
    &:hover {
        background-color: $color-highlight;
    }
}



.custom-dropdown-wrapper {
    .custom-dropdown-popup {
        .card-header {
            .title i.pull-left,
            .title i.pull-right {
                line-height: 1.4rem; // fix when it's floated
            }

            .title i:after {
                font-family: $font-family-icons-fa;
                font-size: 0.688rem;
            }

            .primary-title i:after {
                content: $tpl-fa-icon-arrow-thick-right;
                color: $color-light-7d;
            }

            .secondary-title {
                color: $color-dark-blue;

                i:after {
                    content: $tpl-fa-icon-arrow-thick-left;
                    font-family: $font-family-icons-fa;
                }
            }
        }

        .card-block {
            .content {
                .help-text i:after {
                    content: "\f05a";
                    font-style: normal;
                    font-family: $font-family-icons-fa;
                    margin-right: 0.2rem;
                }

                &.secondary-content .custom-range.selected,
                &.secondary-content .custom-range:hover {
                    background-color: $color-highlight;
                    color: #fff;
                }
            }
        }
    }

    &.active {
        .custom-dropdown-clear-button button {
            background-color: transparent;
            border-color: transparent;
            color: $color-dark;
            padding-left: 6px;
            padding-right: 4px;

            i:after {
                @include tpl-linear-icon($tpl-icon-li-x);
                font-size: 16px;
                line-height: normal;
                margin-top: 2px;
            }
        }
    }
}
/*==========================================================================*/
/* Bootstrap adjustments - End =============================================*/
/*==========================================================================*/


/* qTip */
.qtip-default {
    max-width: 220px;
    background: #fff;
    font-size: 13px;
    color: $color-dark;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    min-height: 55px;
    padding: 15px 20px;
    margin-left: 3px;

    &.qtip-pos-rt {
        margin-left: 0;
        margin-right: 3px;
    }

    .qtip-content {
        line-height: normal;
        padding: 3px 0 0;
    }
}

.global-react-popup {
    .close:after {
        @include tpl-linear-icon($tpl-icon-li-x);
        font-size: 18px;
    }

    .content .form-holder .form-actions .btn-link {
        color: $color-dark-blue;
    }

    &.popup-absolute{
        .content-wrapper{
            background: inherit;
        }
    }
}


.panel {
    border: 1px solid $border-color;
    box-shadow: none;
}
.panel .panel-heading {
    background: $color-light;
    border-bottom: 1px solid $border-color;
    padding: 0;
}
.panel .panel-heading .module-title {
    display: block;
    float: left;
    text-transform: uppercase;
    color: $color-light-a7;
    font-size: 13px;
    padding: 16px 20px;
}
.panel .panel-heading .panel-actions {}
.panel .panel-heading .panel-actions i.fa {
    border-left: 1px solid $border-color;
    color: $color-light-7d;
    font-size: 19px;
    line-height: 45px;
    padding: 3px 15px 0;
}
.panel .panel-body .no-results {
    color: $color-light-a7;
    margin: 0 0 15px;
}


.filters-wrapper-dropdown .dropdown-menu .li {
    //border-bottom: 1px solid $color-gray-f1;
    padding: $dropdown-items-vertical-padding 18px;
}
//.filters-wrapper-dropdown .dropdown-menu .li.last {
//    border-bottom: none;
//}
.filters-wrapper-dropdown .dropdown-menu .li label {
    cursor: pointer;
    font-weight: normal;
    margin: 0 0 0 6px;
}




/*==========================================================================*/
/* Buttons - Start =========================================================*/
/*==========================================================================*/
.tpl-button,
button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
    @include tpl-button;

    &.mfp-close {
        background-color: transparent;
        &:hover {
            border-color: inherit;
            background-color: inherit;
            .alternate-style & {
                background-color: transparent;
            }
        }
    }
}


.tpl-button-small,
button.tpl-button-small,
input.tpl-button-small[type="button"],
input.tpl-button-small[type="submit"],
input.tpl-button-small[type="reset"] {
    @include tpl-button;
    @include tpl-button-base-small;
}
/*==========================================================================*/
/* Buttons - End ===========================================================*/
/*==========================================================================*/



/*==========================================================================*/
/* Paging - Start ==========================================================*/
/*==========================================================================*/
body,
.search-modules-results-container {
    .paging-toolbar {
        .goToPage {
            background: none;
            border: none;
            box-shadow: none;
            border-radius: 0;
            font-family: $font-family;
            text-decoration: none;
            color: $color-dark;
            font-size: 12px;
            font-weight: 700;
            padding: 2px 5px;
            margin: 2px 5px;

            &.prev,
            &.next {
                color: transparent;
                position: relative;
            }

            &.prev {
                text-align: right;
            }
            &.next {
                text-align: left;
            }

            &.prev:before,
            &.next:after {
                display: inline-block;
                color: $color-dark;
            }

            &.prev:before {
                min-width: 85px;
                content: "< Previous";
            }
            &.next:after {
                min-width: 70px;
                content: "Next >";
            }

            &.first, &.last {
                display: none;
            }

            &:hover {
                background: none;
            }

            &.current {
                background: none;
                border: none;
                color: $color-blue;
            }
        }
    }
}
/*==========================================================================*/
/* Paging - End ============================================================*/
/*==========================================================================*/


/*==========================================================================*/
/* jQuery UI adjustments - Start ===========================================*/
/*==========================================================================*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
    border-color: $border-color;
    font-weight: 400;
    color: $color-dark;
}

$tpl-datepicker-arrow-size: 22px;
.ui-datepicker {
    width: 220px;
    box-shadow: 0 0 5px $border-color;

    .ui-datepicker-header {
        background: $color-dark;
        font-size: 20px;
    }

    .ui-datepicker-title {
        line-height: normal;

        select {
            height: auto;
            width: auto;
            line-height: normal;
            font-weight: 400;
            font-size: $font-size-small;
            background-image: none;
            padding: 4px 3px 3px;
            margin: 2px;
        }
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        cursor: pointer;
        top: 0;

        &.ui-state-hover,
        span {
            background: none;
            border: none;
        }

        &:before {
            font-family: $font-family-icons-fa;
            position: absolute;
            top: 50%;
            left: 50%;
            width: $tpl-datepicker-arrow-size;
            height: $tpl-datepicker-arrow-size;
            line-height: $tpl-datepicker-arrow-size;
            vertical-align: middle;
            text-align: center;
            color: $color-white;
            font-size: 18px;
            margin: (-$tpl-datepicker-arrow-size/2) 0 0 (-$tpl-datepicker-arrow-size/2);
        }
    }

    .ui-datepicker-prev {
        left: 0;

        &:before {
            content: "\f104";
        }
    }
    .ui-datepicker-next {
        right: 0;

        &:before {
            content: "\f105";
        }
    }

    table {
        font-size: 12px;
    }

    th {
        font-weight: 400;
        padding: 5px 2px 4px;
    }

    td {
        background: $color-light;
        border: 1px solid $border-color;
        padding: 0;

        span,
        a {
            font-family: $font-family;
            font-weight: 400;
            letter-spacing: normal;
            line-height: normal;
            text-shadow: none;
            padding: 5px 5px 4px;

            &, &.ui-state-default {
                border: none;
            }

            &.ui-state-hover {
                background: $color-highlight;
                color: $color-white;
            }
        }

        &.ui-datepicker-current-day {
            span,
            a {
                box-shadow: none;
                text-shadow: none;
                background: $color-blue;
                color: $color-white;
                font-weight: 400;
            }
        }

        &.ui-datepicker-week-end {
            span,
            a {
                color: $color-blue;
                background-color: $color-light; // $color-light-db
                background-image: none;
                text-shadow: none;

                &.ui-state-hover {
                    background: $color-highlight;
                    color: $color-white;
                }
            }
        }

        &.ui-datepicker-today {
            span,
            a {
                font-weight: normal;
                text-shadow: none;
            }
        }
    }

    .ui-datepicker-buttonpane button {
        @include tpl-button($size: "small-auto");
        margin: 0 0 5px;
    }
}

/* Auto-complete */
.ui-autocomplete-input.ui-autocomplete-loading,
.search-modules-results-responsive-view .search-modules-results-fields .ui-autocomplete-loading {
    background-image: url('../img/ajax-loader.gif') !important;
    background-position: center right 16px !important;
    background-repeat: no-repeat;
}

.ui-autocomplete {
    border-radius: $field-border-radius;
    border: $field-border;
    border-top: 0;
    padding: 0;
    margin-top: -3px;

    &.ui-menu .ui-menu-item a {
        color: $color-gray;
        font-size: $font-size;
        line-height: normal;
        border-radius: $field-border-radius;
        padding: 8px 14px;

        .highlight-row {
            border: none;
            padding: 0;

            .highlight-label {
                @include tpl-heading(6);
                font-size: $font-size;
                color: $color-gray;
            }
            .highlight-item .highlight-fragment {
                @include tpl-heading(6);
                font-size: ceil($font-size * 0.8);
                color: $color-gray;
                font-style: italic;
            }
        }

        &.ui-state-hover,
        &.ui-state-focus {
            color: $color-gray;
            border: none;
            background: $color-white;
            border-radius: $field-border-radius;
            margin: 0;
        }

        &:hover,
        &.ui-state-hover {
            background: $color-light;
            cursor: pointer;
        }
    }
}
/*==========================================================================*/
/* jQuery UI adjustments - End =============================================*/
/*==========================================================================*/


/*==========================================================================*/
/* Custom Alert - Start ====================================================*/
/*==========================================================================*/

.customAlert {
    font-family: $font-family;
    background: $color-light;
    min-width: 510px;
    border: none;

    .customAlertInner {
        padding-bottom: 25px;

        .customAlertTitle {
            font-family: $font-family;
            background: $color-dark;
            color: $color-white;
            font-size: 28px;
            font-weight: 300;
            position: relative;
            text-align: center;
            overflow: hidden;
            float: none;
            padding: 13px 38px 12px 20px;

            a.closeAlert {
                @include tpl-close-icon("absolute-vertical");
                background: transparent;
            }
        }


        .customAlertButtons {
            &, &.right {
                text-align: left;
                padding: 0 25px;
            }

            button {
                @include tpl-button($size: "small");
                margin: 0 5px 0 0;
            }

            &.right .customAlertButtonOk {
                width: 100%;
                margin: 0;
            }
        }

        .customAlertMsg {
            font-family: $font-family;
            color: $color-dark;
            font-weight: 300;
            padding: 25px 25px 15px;

            .description {
                font-size: 20px;
                font-weight: 300;
                color: $color-dark;
            }

            // Stay Connected popup
            ul.list--bare {
                list-style: none;
                padding-left: 0;

                li {
                    margin: 8px 0;
                }
            }

            textarea,
            input[type=text] {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            input[type=text] {
                @include tpl-input-field("small");
            }
        }

        .customAlertError {
            font-family: $font-family;
            color: $color-error;
            text-align: left;
            padding: 0 20px 10px;
        }
    }

    // New Campaign popup
    &.newCampaignOptions {
        min-width: 410px;

        .customAlertInner {
            padding: 0;

            .customAlertMsg {
                padding: 28px 48px;

                .pop-up-buttons {
                    padding: 25px 0 17px;

                    a {
                        @include tpl-button($size: "small");
                        width: 100%;
                        margin: 3px 0;
                    }
                }
            }
        }
    }
    // Advocacy Donate Popup
    &.advocacy-donate-popup-custom-alert .customAlertInner {
        .customAlertMsg {
            padding: 25px 25px 10px;
            font-weight: 500;
            textarea,
            input[type=text] {
                margin-top: 0;
                margin-bottom: 0;
            }
            .amount-holder img {
                display: none;
            }
            .anonymous-label {
                padding-top: 15px;
            }
            .radio-wrapper {
                margin-left: 10px;
                width: 65px !important;
                padding: 15px 0 0;
                font-weight: 300;
            }
            .advocacy-donate-popup {
                padding: 15px 15px 0;
                .logged-in-info-holder {
                    height: 30px;
                    b {
                        font-weight: 300;
                    }
                }
                .field-label {
                    display: none;
                }
            }
        }
        .customAlertButtons {
            text-align: left;
            padding: 0 25px 10px;
            .customAlertButtonOk {
                margin: 0 15px;
            }
            button {
                @include tpl-button($size: "small");
            }
        }
    }

    // Send to a Friend
    &.send-to-friend .customAlertInner .customAlertMsg {
        padding-bottom: 5px;

        img.required {
            display: none;
        }
    }

    // Add to interest list (confirmation)
    &.add-to-interest-list .customAlertInner .customAlertButtons button {
        width: 100%;
        margin-top: 15px;
    }

    @media (max-width:510px) {
        &, &.newCampaignOptions {
            min-width: 96%;
        }

        &.newCampaignOptions .customAlertInner .customAlertMsg {
            padding: 22px 20px 10px;
        }
        &.advocacy-donate-popup-custom-alert {
            width: 100% !important;
            .customAlertInner {
                .customAlertMsg {
                    padding: 22px 0 10px;
                }
                .customAlertButtons {
                    padding: 0;
                }
            }
        }
    }
}

/* Background overlay color adjustment */
body .customAlertModalFrame,
#users-modules-login-popup-blur,
#users-modules-register-popup-blur {
    background: $color-black;
    opacity: 0.3;
}


/* Custom radio */
.customAlert .customAlertInner .customAlertMsg .radio-wrapper input[type=radio] {
    display: none;
}

.customAlert .customAlertInner .customAlertMsg .radio-wrapper label:before {
    content: " ";
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $color-white;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -4px;
}

.customAlert .customAlertInner .customAlertMsg .radio-wrapper label.checked:before {
    font-family: $font-family-icons-fa;
    content: "\f111";
    color: $color-blue;
    background: $color-white;
    text-align: center;
    line-height: 25px;
    font-size: 14px;
}

/*==========================================================================*/
/* Custom Alert - End  =====================================================*/
/*==========================================================================*/


/*==========================================================================*/
/* Tags Helper (multiselect) ===============================================*/
/*==========================================================================*/
.form-input-wrapper.multiselect .multiselect-options {
    border: $field-border;
    padding: 3px 14px;

    label {
        line-height: normal;
        padding: 3px 3px 3px 22px;
        color: $color-light-7d;
        margin: 6px 0;

        &:before {
            //@include tpl-linear-icon("\e934");
            @include tpl-linear-icon("\e959");
            background: none;
            top: 50%;
            height: 18px;
            line-height: 18px;
            vertical-align: middle;
            margin-top: -9px;
        }

        &.checked {
            color: $color-tag;
        }

        &.checked:before {
            background: none;
        }
    }
}


/*==========================================================================*/
/* Global giving form - Start  =============================================*/
/*==========================================================================*/
.tpl-elements-giving-form {
    position: relative;

    .tpl-field {
        float: left;
        width: 100%;
        position: relative;
        padding-right: $tpl-simple-form-button-width - $field-border-width;

        &:before,
        &:after {
            @include tpl-input-field-placeholder;
            position: absolute;
            top: 50%;
            font-size: $field-font-size;
            height: 20px;
            line-height: 20px;
            vertical-align: middle;
            margin-top: -10px;
        }
        &:before {
            left: 17px;
            content: "$";
        }
        &:after {
            content: "USD";
            right: $tpl-simple-form-button-width + 14px;
        }

        input[name=amount] {
            background-color: $color-white;
            padding-left: 30px;
            padding-right: 55px;
            border: 1px solid $color-gray;
        }
    }

    .tpl-amount-button-holder {
        display: inline-block;
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;

        .tpl-amount-submit {
            @include tpl-button;
            display: inline-block;
            width: $tpl-simple-form-button-width;
            padding-left: 2px;
            padding-right: 2px;
        }
    }

    .tpl-other-country {
        @include tpl-body;
        text-align: center;
        color: $color-white;
        margin-top: 32px;

        .tpl-flag-icon {
            padding: 1px 8px 0 0;
        }

        a {
            color: $color-white;
            text-decoration: underline;
        }
    }

    &.tpl-elements-giving-form-small {
        .tpl-field {
            padding-right: $tpl-simple-form-button-width-small - $field-border-width;

            &:after {
                right: $tpl-simple-form-button-width-small + 14px;
            }

            input[name=amount] {
                height: $field-height-small;
            }
        }

        .tpl-amount-button-holder .tpl-amount-submit {
            @include tpl-button-base-small;
            width: $tpl-simple-form-button-width-small;
            padding-left: 2px;
            padding-right: 2px;
        }
    }
}

/*==========================================================================*/
/* Global giving form - End  ===============================================*/
/*==========================================================================*/

/*==========================================================================*/
/* Global giving details - Start  ==========================================*/
/*==========================================================================*/

.tpl-funding-wrapper,
.tpl-architect-project-featured {
    .tpl-funding-info {
        .tpl-value {
            display: block;
            font-size: 20px;
            font-weight: 300;
        }

        .tpl-label {
            display: block;
            font-size: 16px;
            font-weight: 700;
        }

        &.tpl-raised-amount,
        &.tpl-needed {
            margin-top: 35px;
            margin-bottom: 17px;
        }
    }

    .tpl-funding-progress-wrapper {
        .tpl-funding-progress {
            background-color: #fff;
            border-radius: 1px;

            .tpl-funding-progress-level {
                height: 10px;
                border-top-left-radius: 1px;
                border-bottom-left-radius: 1px;
                background-color: $color-green;
            }
        }

        .tpl-funding-percentage-wrapper {
            padding-top: 5px;

            span span {
                display: inline-block;
                font-weight: 300;
                font-size: 15px;
            }
            span.tpl-funding-percentage {
                float: left;
                text-align: right;

                span {
                    width: 30px;
                    text-align: center;
                    margin-right: -15px;
                }
            }
            span.tpl-funding-percentage-total {
                float: right;
            }
        }
    }

    // Percentage >= 80
    &.tpl-funding-almost-complete .tpl-funding-progress-wrapper {
        .tpl-funding-progress .tpl-funding-progress-level {
            border-top-right-radius: 1px;
            border-bottom-right-radius: 1px;
        }

        .tpl-funding-percentage-wrapper {
            span.tpl-funding-percentage span {
                text-align: right;
                margin-right: 0;
            }
            span.tpl-funding-percentage-total {
                display: none;
            }
        }
    }
}

/*==========================================================================*/
/* Global giving details - End  ============================================*/
/*==========================================================================*/


/*==========================================================================*/
/* Global Tags - Start =====================================================*/
/*==========================================================================*/
.tpl-elements-get-tags {
    font-size: 12px;
    text-transform: uppercase;

    .tpl-label {
        font-weight: 700;
        color: $color-tag-label;
    }

    .tpl-tag {}
    a.tpl-tag {
        font-weight: 500;
        color: $color-tag;

        &:hover {
            color: tpl-hover-lighten($color-tag);
        }
    }

    .tpl-sep {
        font-weight: 500;
        color: $color-black;
    }

    &.tpl-tag-categories-yes {
        .tpl-all-tags-label {
            display: none;
        }

        .tpl-tag-category {
            display: block;

            .tpl-sep-tag-cat {
                display: none;
            }
        }
    }
}
/*==========================================================================*/
/* Global Tags - End =======================================================*/
/*==========================================================================*/


/*==========================================================================*/
/* Summary view (Missionaries, Projects, etc.) - Start =====================*/
/*==========================================================================*/

.tpl-architect-summary {
    float: left;
    width: 33.3%;
    max-width: 600px; // width of image

    .tpl-content-wrapper {
        background-color: $color-white;
        margin: 8px;
    }

    .tpl-retain-ratio {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 66.6%;
    }

    .tpl-image-and-title {
        .tpl-image-holder,
        .tpl-title-holder {
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .tpl-image-holder {
            z-index: 1;
        }
        .tpl-title-holder {
            z-index: 3;

            .tpl-title {
                color: $color-white;
                padding: 25px;
            }
        }

        .tpl-color-overlay {
            @include tpl-full-spread($background-color: true);
            z-index: 2;
        }
    }

    .tpl-elements-giving-form {
        //border-bottom: 1px solid $border-color;
    }

    .tpl-content {
        .tpl-details {
            min-height: 245px;
            padding: 18px 25px 10px;
        }

        .tpl-title-holder {
            margin: 0 0 13px;
        }
        .tpl-title {
            @include tpl-heading(5);
            font-size: 26px;
        }

        .tpl-elements-get-tags {
            margin: 0 0 10px;
        }

        .tpl-full-view-button {
            @include tpl-button;
            width: 100%;
        }
    }

    // With Title over Image (Opportunity)
    &.tpl-title-over-image .tpl-content .tpl-elements-get-tags {
        margin: 10px 0 15px;
    }



    @media (max-width: 1110px) {
        width: 50%;
    }

    @media (max-width: 700px) {
        width: 100%;
        float: none;
        margin: 0 auto;

        .tpl-content-wrapper {
            margin: 10px 0 20px;
        }

        .tpl-content .tpl-details {
            min-height: 0;
            padding: 18px 20px;
        }
    }

    @media all and (max-width: 760px) and (min-width: 701px) {
        .tpl-content .tpl-details {
            min-height: 260px;
        }
    }

    @media (max-width: 450px) {
        .tpl-image-and-title .tpl-title {
            font-size: 22px;
            padding: 18px 22px;
        }
    }
}

/*==========================================================================*/
/* Summary view (Missionaries, Projects, etc.) - End =======================*/
/*==========================================================================*/

/*==========================================================================*/
/* Full view (Missionaries, Projects, etc.) - Start ========================*/
/*==========================================================================*/
.tpl-architect-full {
    .tpl-hero {
        .tpl-title,
        .tpl-subtitle {
            color: $color-white;
        }
        .tpl-elements-giving-form {
            max-width: 340px;
            margin: $default-content-padding * 1.5 auto 0;
        }
        .tpl-button{
            @include tpl-button($solid:true);
        }
    }

    .tpl-main-content {
        padding-top: 55px;
        padding-bottom: 50px;

        .tpl-main-content-side {
            float: left;
            width: 30%;
            max-width: 360px;

            .tpl-button {
                @include tpl-button($size: "small");
                width: 100%;
            }

            .tpl-buttons {
                padding-top: 24px;

                .tpl-button {
                    @include tpl-button;
                    margin: 1px 0;

                    &.tpl-send {
                        @include tpl-button;
                    }
                    &.tpl-pray,
                    &.tpl-motion-interest-list {
                        @include tpl-button;
                    }

                    &, &.tpl-send, &.tpl-pray, &.tpl-motion-url, &.tpl-motion-interest-list {
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }
            }
        }
        .tpl-main-content-main {
            float: right;
            width: 70%;
            padding-left: 60px;

            .tpl-title,
            .tpl-elements-get-tags {
                padding-bottom: $default-content-padding;
            }

            .tpl-body {
                padding-right: 30px;
            }
        }
    }

    @media (max-width: 890px) {
        .tpl-main-content {
            .tpl-main-content-side {
                width: 39%;

                .tpl-buttons .tpl-button {
                    &, &.tpl-send, &.tpl-pray, &.tpl-motion-application, &.tpl-motion-interest-list {
                        @include tpl-button-base-small;
                    }
                }
            }
            .tpl-main-content-main {
                width: 61%;
                padding-left: 25px;
            }
        }
    }

    @media (max-width: 700px) {
        .tpl-hero.tpl-hero-larger .tpl-hero-content {
            height: auto;
            padding: 65px 0;
        }

        .tpl-main-content {
            .tpl-main-content-side,
            .tpl-main-content-main {
                float: none;
                width: 100%;
            }

            .tpl-main-content-side {
                padding-bottom: $default-content-padding * 1.5;
            }

            .tpl-main-content-main {
                padding-left: 0;
            }
        }
    }
}
/*==========================================================================*/
/* Full view (Missionaries, Projects, etc.) - End ==========================*/
/*==========================================================================*/

/*==========================================================================*/
/* Summary With Buttons (Article, Projects, Opportunities) - Start =========*/
/*==========================================================================*/

.tpl-architect-summary-with-button {
    padding-bottom: 20px;
    .tpl-architect-summary-with-button-inner .tpl-image {
        width: 100%;
        height: 300px;
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        border-radius: 2px 2px 0 0;
        .tpl-info {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: table;

            .tpl-info-inner {
                display: table-cell;
                text-align: center;
                vertical-align: middle;
                padding: 0 10px;
            }
            .tpl-elements-get-tags {
                text-transform: none;
                .tpl-label.tpl-all-tags-label {
                    display: none;
                }
                .tpl-tag-category,
                & .tpl-sep.tpl-sep-tag{
                    color: $color-white;
                    font-size: 1.429rem;
                    font-weight: 300;
                }
            }
            .tpl-title {
                color: $color-white;
                font-size: 1.714rem;
                font-weight: 800;
                text-transform: uppercase;
                padding-bottom: 0.8rem;
                padding-top: 0.8rem;
            }

            .tpl-button-wrapper {
                padding: 20px;
                a {
                    @include tpl-button($size: "small");
                }
            }
        }
    }
}

/*==========================================================================*/
/* Summary With Buttons (Article, Projects, Opportunities) - End ===========*/
/*==========================================================================*/

/*==========================================================================*/
/* Classes for bg colors ===================================================*/
/*==========================================================================*/
.tpl-background-dark {
    background-color: $color-dark;
}
.tpl-background-dark-blue {
    background-color: $color-dark-blue;
}
.tpl-background-blue {
    background-color: $color-blue;
}
.tpl-background-light-blue {
    background-color: $color-light-blue;
}
.tpl-background-green {
    background-color: $color-green;
}
.tpl-background-gray {
    background-color: $color-gray;
}
.tpl-background-light {
    background-color: $color-light;
}
.tpl-background-white {
    background-color: $color-white;
}


/*==========================================================================*/
/* Classes for centering ===================================================*/
/*==========================================================================*/
.tpl-centered {
    @include tpl-centered-content();
}

.tpl-centered-1100 {
    @include tpl-centered-content(1100px);
}

.tpl-centered-1000 {
    @include tpl-centered-content(1000px);
}

.tpl-centered-960 {
    @include tpl-centered-content(960px);
}

.tpl-centered-850 {
    @include tpl-centered-content(850px);
}

.tpl-centered-800 {
    @include tpl-centered-content(800px);
}
/*==========================================================================*/
/* Columns / Grid - Start ==================================================*/
/*==========================================================================*/
.tpl-col {
    display: block;
    float: left;
    min-height: 1px;
}

/* GRID OF TWO */
.tpl-col-1-2 {
    @extend .tpl-col;
    width: 50%;
}

/* GRID OF THREE  */
.tpl-col-1-3 {
    @extend .tpl-col;
    width: 33.3333%;
}

.tpl-col-2-3 {
    @extend .tpl-col;
    width: 66.6666%;
}

/* GRID OF FOUR  */
.tpl-col-1-4 {
    @extend .tpl-col;
    width: 25%;
}

.tpl-col-2-4 {
    @extend .tpl-col;
    width: 50%;
}

.tpl-col-3-4 {
    @extend .tpl-col;
    width: 75%;
}

/* GRID OF FIVE */
.tpl-col-1-5 {
    @extend .tpl-col;
    width: 20%;
}

.tpl-col-2-5 {
    @extend .tpl-col;
    width: 40%;
}

.tpl-col-3-5 {
    @extend .tpl-col;
    width: 60%;
}

.tpl-col-4-5 {
    @extend .tpl-col;
    width: 80%;
}

@media (max-width: 1024px) {
    .tpl-col-1-3 {
        float: none;
        width: 100%;
    }
}
@media (max-width: 1000px) {
    .tpl-col-1-3 {
        float: none;
        width: 100%;
    }
    .tpl-col-1-4 {
        width: 50%;
    }
    .tpl-col-1-5 {
        width: 40%;
    }
    .tpl-col-4-5 {
        width: 60%;
    }
}

@media (max-width: 800px) {
    .tpl-col-1-3,
    .tpl-col-1-2,
    .tpl-col-2-3,
    .tpl-col-2-4,
    .tpl-col-3-4,
    .tpl-col-1-5,
    .tpl-col-4-5 {
        float: none;
        width: 100%;
    }
}

@media (max-width: 600px) {
    .tpl-col-1-4,
    .tpl-col-2-5,
    .tpl-col-3-5 {
        float: none;
        width: 100%;
    }
}
/*==========================================================================*/
/* Columns / Grid - End ====================================================*/
/*==========================================================================*/





/*==========================================================================*/
/* Global Padding Classes - Start ==========================================*/
/*==========================================================================*/
* {
    &.padding-top {
        padding-top: $default-content-padding;
    }
    &.padding-bottom {
        padding-bottom: $default-content-padding;
    }
    &.padding-left {
        padding-left: $default-content-padding-sides;
    }
    &.padding-right {
        padding-right: $default-content-padding-sides;
    }
    &.padding-sides {
        padding-left: $default-content-padding-sides;
        padding-right: $default-content-padding-sides;
    }

    &.padding-top.padding-x-1-5,
    &.padding-top.padding-v-x-1-5 {
        padding-top: $default-content-padding * 1.5;
    }
    &.padding-bottom.padding-x-1-5,
    &.padding-bottom.padding-v-x-1-5 {
        padding-bottom: $default-content-padding * 1.5;
    }
    &.padding-left.padding-x-1-5,
    &.padding-left.padding-h-x-1-5 {
        padding-left: $default-content-padding-sides * 1.5;
    }
    &.padding-right.padding-x-1-5,
    &.padding-right.padding-h-x-1-5 {
        padding-right: $default-content-padding-sides * 1.5;
    }
    &.padding-sides.padding-x-1-5,
    &.padding-sides.padding-h-x-1-5 {
        padding-left: $default-content-padding-sides * 1.5;
        padding-right: $default-content-padding-sides * 1.5;
    }

    &.padding-top.padding-x-2-5,
    &.padding-top.padding-v-x-2-5 {
        padding-top: $default-content-padding * 2.5;
    }
    &.padding-bottom.padding-x-2-5,
    &.padding-bottom.padding-v-x-2-5 {
        padding-bottom: $default-content-padding * 2.5;
    }
    &.padding-left.padding-x-2-5,
    &.padding-left.padding-h-x-2-5 {
        padding-left: $default-content-padding-sides * 2.5;
    }
    &.padding-right.padding-x-2-5,
    &.padding-right.padding-h-x-2-5 {
        padding-right: $default-content-padding-sides * 2.5;
    }
    &.padding-sides.padding-x-2-5,
    &.padding-sides.padding-h-x-2-5 {
        padding-left: $default-content-padding-sides * 2.5;
        padding-right: $default-content-padding-sides * 2.5;
    }

    @for $i from 2 through 10 {
        // All sides
        &.padding-top.padding-x-#{$i} {
            padding-top: $default-content-padding * $i;
        }
        &.padding-bottom.padding-x-#{$i} {
            padding-bottom: $default-content-padding * $i;
        }
        &.padding-left.padding-x-#{$i} {
            padding-left: $default-content-padding-sides * $i;
        }
        &.padding-right.padding-x-#{$i} {
            padding-right: $default-content-padding-sides * $i;
        }
        &.padding-sides.padding-x-#{$i} {
            padding-left: $default-content-padding-sides * $i;
            padding-right: $default-content-padding-sides * $i;
        }

        // Vertical
        &.padding-top.padding-v-x-#{$i} {
            padding-top: $default-content-padding * $i;
        }
        &.padding-bottom.padding-v-x-#{$i} {
            padding-bottom: $default-content-padding * $i;
        }

        // Horizontal
        &.padding-left.padding-h-x-#{$i} {
            padding-left: $default-content-padding-sides * $i;
        }
        &.padding-right.padding-h-x-#{$i} {
            padding-right: $default-content-padding-sides * $i;
        }
        &.padding-sides.padding-h-x-#{$i} {
            padding-left: $default-content-padding-sides * $i;
            padding-right: $default-content-padding-sides * $i;
        }
    }
}
/*==========================================================================*/
/* Global Padding Classes - End ============================================*/
/*==========================================================================*/


/*==========================================================================*/
/* Global Mobile Menu Flyout Trigger - Start ===============================*/
/*==========================================================================*/

.tpl-hero-trigger,
.tpl-header-trigger {
    cursor: pointer;
    &:after {
        @include tpl-linear-icon;
        display: block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        vertical-align: middle;
        font-size: 16px;
        text-align: center;
    }
}

.tpl-header-trigger {
    color: $color-dark;
    height: 20px;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    //width: 70px;
    right: 0;
}

.tpl-hero-trigger {
    font: $font-family;
    color: $color-white;
}


.tpl-mobile-menu-trigger {
    span {
        display: block;
        float: left;
        font-size: 15px;
        font-weight: 300;
        text-transform: uppercase;
        text-decoration: none;
    }

    &:after {
        color: $color-light-fa;
        display: block;
        float: left;
        content: '\e92b';
        font-size: 20px;
        margin: -1px 0 0 4px;
    }
    @media only screen and (max-width: 360px) {
        width: 20px;
        span {
            display: none;
        }
    }
}

/*==========================================================================*/
/* Global Mobile Menu Flyout Trigger - End ========================================*/
/*==========================================================================*/