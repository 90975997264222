@import "variables";

/*===============================*/
/* jQuery UI adjustments - Start */
/*===============================*/
//.ui-widget-header {
//    font-weight: 400;
//    font-size: 24px;
//}

.ui-widget-overlay {
    background: $color-overlay;
    z-index: $z-index-popups - 1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.ui-dialog {
    border-radius: 0;
    background: $color-light-e8;
    z-index: $z-index-popups;
    border: none;
    padding: 0;
    transition: all 0.5s ease;
    
    .ui-dialog-titlebar {
        background: none;
        @include tpl-popup-title();
        border-radius: 0;
        cursor: move;
        border: none;

        .ui-dialog-title {
            @include tpl-heading(2);
            color: $color-white;
        }
        .ui-button {
            @include tpl-icon-x("absolute-vertical", 18px);
            right: 18px;
            z-index: $z-index-popup-close-icon;
            background: none;
            border: none 0;
            border-radius: 0;
            padding: 0;

            .ui-button-icon-primary,
            .ui-button-text {
                display: none;
            }
        }
    }

    .ui-dialog-content {
        font-family: $font-family;
        border: 1px solid $color-gray;
        border-bottom: none;
        padding: 20px 20px 5px;

        label {
            display: inline-block;
            font-weight: 700;
            padding-bottom: 5px;
        }

        input {
            @include tpl-input-field;
            padding: $field-padding !important;
        }

        textarea {
            @include tpl-input-field;
            @include tpl-input-field-textarea;
            padding-top: 6px !important;
            padding-bottom: 6px !important;
        }

        #recaptcha_response_field {
            border: none !important;
            height: auto !important;
        }
        #recaptcha_privacy * {
            font-size: 12px;
        }
        .system-messages-captcha{
            padding-bottom: 1px;
        }
    }

    .ui-dialog-buttonpane {
        background: $color-light-e8;
        border: 1px solid $color-gray;
        border-top: none;
        padding: 20px 20px 25px;
        margin-top:0;

        .ui-button {
            @include tpl-button($size: "small");
            background-image: none;
            margin: 0 5px 0 0;
        }
    }

    .ui-resizable-handle {
        display: none !important;
    }

    // Responsive - Custom Alert
    @media only screen and (max-width: 470px) {
        width: 320px !important;
    }
}




.ui-datepicker {
    width: 220px;
    box-shadow: none;

    .ui-datepicker-header {
        background-color: $header-title-background;
        color: $header-title-color;
        font-size: 16px;
        padding: 10px 0;
    }

    .ui-datepicker-title {
        line-height: normal;

        select {
            height: auto;
            line-height: normal;
            font-weight: 400;
            border-color: $header-title-background;
            background: $header-title-background;
            color: $header-title-color;
            padding: 4px 3px 3px;
            margin: 2px 0;
        }
    }

    .ui-datepicker-prev,
    .ui-datepicker-prev.ui-state-hover,
    .ui-datepicker-next.ui-state-hover {
        background: none;
        border: none;
    }

    table {
        font-size: 12px;
    }

    th {
        font-weight: 400;
    }

    td {
        background: $color-white;
        border: 1px solid $border-color;

        span,
        a {
            font-family: $font-family;
            font-weight: 400;
            letter-spacing: normal;
            line-height: normal;
            text-shadow: none;
            padding: 5px 0 6px;

            &.ui-state-hover {
                background: lighten($color-active-state, 10%);
                color: $color-white;
            }
        }

        &.ui-datepicker-current-day {
            span,
            a {
                box-shadow: none;
                text-shadow: none;
                background: lighten($color-active-state, 10%);
                font-weight: 400;
            }


        }

        &.ui-datepicker-week-end {
            span,
            a {
                color: $color-red;
                text-shadow: none;

                &.ui-state-hover {
                    background: lighten($color-active-state, 10%);
                    color: $color-red;
                }
            }
        }

        &.ui-datepicker-today {
            span,
            a {
                font-weight: normal;
                text-shadow: none;
            }
        }

        &.ui-state-disabled {
            opacity: 0.4
        }
    }

    .ui-datepicker-buttonpane button {
        @include tpl-button($size: "small-auto");
        margin: 0 0 5px;
    }
}

/*=============================*/
/* jQuery UI adjustments - End */
/*=============================*/